import React from 'react';
import {createClassString} from '@components/toolkit/utility';

import styles from './Container.module.scss';

interface Props {
    className?: string;
    children: React.ReactChildren | JSX.Element | JSX.Element[];
}

const Container: React.FC<Props> = ({className, children}) => {
    return (
        <div className={createClassString(styles.container, className)}>
            {children}
        </div>
    );
};
export default Container;
