import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Image from 'gatsby-image';

import styles from './Banner.module.scss';

const Banner = (): JSX.Element | null => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "hero.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <div className={styles.container}>
            <Image className={styles.image} fluid={data.placeholderImage.childImageSharp.fluid}/>
        </div>
    );
};

export default Banner;
