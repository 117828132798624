import React from 'react';
import Container from '@components/Layouts/Container';
import Layout from '@components/PageLayout';
import HeroBanner from '@components/Banners/Hero';
import NotesBanner from '@components/Banners/Notes';
import WalletBanner from '@components/Banners/Wallet';
import SEO from '../components/seo';
import Typography from '@components/Typography';

import styles from './index.module.scss';

const KKBO = (
    <Typography bold color="secondary">
        {' '}KKBO{' '}
    </Typography>
);
const IndexPage: React.FC = () => {
    return (
        <Layout>
            <SEO title="Home" />
            <HeroBanner />
            <Container>
                <div className={styles.block}>
                    <Typography
                        color="secondary"
                        tag="h1"
                        bold
                    >
                        {KKBO} is a Self-Accountable Saving Application
                    </Typography>
                    <div className={styles.container}>
                        <div>
                            <div className={styles.section}>
                                <div>
                                    {KKBO} is based off the Japanese saving method called
                                    Kakeibo, which encourages a strong saving mentality.
                                </div>
                                <div>
                                    We built {KKBO} to help you practice Kakeibo in an efficient and easy manner.
                                </div>
                            </div>
                            <div>
                                <Typography tag="h2" bold color="secondary">
                                    Our Vision
                                </Typography>
                                <Typography bold>
                                    We want to make budgeting easy.
                                </Typography>
                                <div>
                                    It&apos;s easy to spend money without thinking.
                                    We want to encourage people to be mindful of what they spend.
                                    {KKBO} makes it easy for you to keep track of your budgeting.
                                </div>
                            </div>
                        </div>
                        <div className={styles.imageContainer}>
                            some screenshot of the app will go here
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <Typography tag="h1" color="secondary" bold>
                        What is Kakeibo?
                    </Typography>
                    <WalletBanner />
                    <div>
                        <p><b>Kakeibo</b> is a Japanese method of budgeting. You first split your monthly take-home income into 3 sections:</p>
                        <ol>
                            <li>Necessities - 50%</li>
                            <li>Personal - 30%</li>
                            <li>Savings - 20%</li>
                        </ol>
                        <p>
                            Then, you categorize your areas of spending. For example, getting groceries and paying rent
                            can be considered as "necessities", whereas going out to eat and watching a movie can be "personal" expenses.
                        </p>
                        <p>Savings is a hard constant, meaning that you immediately put away <b>20%</b> of your savings that you do not touch under any circumstances.</p>
                        <p>So for example, if you make <b>$4000</b> a month,</p>
                        <ul>
                            <li><b>$2000</b> goes towards <b>Necessities</b></li>
                            <li><b>$1200</b> goes towards <b>Personal Expenses</b></li>
                            <li><b>$800</b> goes towards <b>Savings</b></li>
                        </ul>
                        <p>This way, you "pay yourself first", allowing you to spend the rest of your money as needed.</p>
                    </div>
                </div>
                <div className={styles.section}>
                    <Typography tag="h1" color="secondary" bold>
                        Keeping Track
                    </Typography>
                    <NotesBanner />
                    <div>
                        <p>The second main aspect of <b>Kakeibo</b> is documentation.</p>
                        <p>
                            Now that you have split your income into those three sections,
                            it is important to document your <b>Necessary</b> and <b>Personal</b> spending.
                        </p>
                        <h3>This is where {KKBO} comes in.</h3>
                        <p>
                            We built {KKBO} so it is easier for you to track your spending. In {KKBO},
                            you can create and organize categories, monitor your spending,
                            and add transactions, and will instantly calculate
                            how much have you spent, and how much is left.
                        </p>
                        <p>
                            {KKBO} will allow you to seemlessly keep track of your spending and
                            and understand all the data that comes with documentation.
                        </p>
                    </div>
                    <h3>Thank you for considering {KKBO} and hope you have a great experience with it.</h3>
                </div>
            </Container>
        </Layout>
    );
};

export default IndexPage;
